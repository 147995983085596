import React, { useState, useRef, useEffect } from "react";
import APIHelper from "api";
import "./upload.css";
import { message, Card, Input, Col, Row, Form, Button, Typography, Tag } from "antd";
import UploadImagePicker from "components/picker/image_picker/index";
import { phone_regex } from "common/regex";

const { Text } = Typography;

function ATNDUpload(props) {
  const [logoLight, setlogoLight] = useState("");
  const [logoDark, setLogoDark] = useState("");
  const [save, setSave] = useState(false);
  const [nameZaloOA, setNameZaloOA] = useState("");
  const [zaloOAId, setzaloOAId] = useState("");
  const [checkFormData, setcheckFormData] = useState(null);
  const [isDisable, setIsDisable] = useState(true);
  function handleLogoLight(value) {
    setlogoLight(value);
  }
  function handleLogoDark(value) {
    setLogoDark(value);
  }
  const formRef = useRef();

  const handleClick = () => {
    if (!save) {
      setSave(true);
      setIsDisable(false);
    } else {
      if (formRef) {
        formRef.current.submit();
      }
    }
  };

  const compareObjects = (a, b) => {
    return JSON.stringify(a) === JSON.stringify(b);
  };

  const onFinish = (values) => {
    // const dataDifferent = compareObjects(values, checkFormData);
    // if(!dataDifferent){
    //   APIHelper.post("app/zalov2/config/updateconfig", { data: values, field: "configs" })
    //     .then((resp) => {
    //       message.success("Thông tin ZNS lưu thành công");
    //       loadData();
    //       setSave(false);
    //       setIsDisable(true);
    //     })
    //     .catch((error) => {
    //       message.error("Có lỗi xảy ra khi lưu thông tin ZNS");
    //     });
    // }
    APIHelper.post("app/zalov2/config/updateconfig", { data: values, field: "configs" })
        .then((resp) => {
          message.success("Thông tin ZNS lưu thành công");
          loadData();
          setSave(false);
          setIsDisable(true);
        })
        .catch((error) => {
          message.error("Có lỗi xảy ra khi lưu thông tin ZNS");
        });
  };

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    APIHelper.get("app/zalov2/config/getconfig")
      .then((resp) => {
        setzaloOAId(resp.oas[0].oa_id);
        setNameZaloOA(resp.oas[0].name);
        setcheckFormData(resp.configs);
        formRef.current.setFieldsValue(resp.configs);
        // setIsDisable(false);
      })
      .catch((error) => {
        message.error("Có lỗi xảy ra khi lấy thông tin ZALO OA");
      });
  };

  return (
    <>
      <Card
        size="small"
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <Row>
          <Col style={{ marginRight: 8 }}>
            <Text style={{ fontWeight: 700 }}>{nameZaloOA}</Text>
          </Col>
          <Col>
            <Tag color="blue">ZALO OA</Tag>
          </Col>
        </Row>
        <Row>
          <Col style={{ marginRight: 8 }}>
            <Text style={{ color: "rgba(0, 0, 0, 0.45)" }}>OA ID : {zaloOAId}</Text>
          </Col>
        </Row>
        <Row justify="center">
          <Col style={{maxWidth:830}}>
            <Text>{`Hình ảnh logo thương hiệu trên OA (400 x 96), nền trong suốt`}</Text>
            <Form layout="vertical" ref={formRef} onFinish={onFinish}>
              <Row  style={{ paddingBottom: 16 }}>
                <Col span={12}>
                  <Form.Item
                    style={{ margin: 0, padding: 8 }}
                    name={"logolight"}
                    rules={[
                      {
                        required: true,
                        type: "string",
                        message: "Vui lòng upload hình ảnh này",
                      },
                    ]}
                  >
                    <UploadImagePicker
                      disabled={isDisable}
                      crop={{ quality: 1, aspect: 3 }}
                      size={{ width: "400px", height: 96 }}
                      hideLibary={true}
                      tags={["logo"]}
                      value={logoLight}
                      onChange={(value) => handleLogoLight(value)}
                    ></UploadImagePicker>
                  </Form.Item>
                  <Row justify="center">
                    <Text>Logo nguyên bản</Text>
                  </Row>
                </Col>
                <Col span={12}>
                  <Form.Item
                    style={{ margin: 0, padding: 8 }}
                    name={"logodard"}
                    rules={[
                      {
                        required: true,
                        type: "string",
                        message: "Vui lòng upload hình ảnh này",
                      },
                    ]}
                  >
                    <UploadImagePicker
                      disabled={isDisable}
                      crop={{ quality: 1, aspect: 3 }}
                      size={{ width: "400px", height: 96 }}
                      hideLibary={true}
                      tags={["logo"]}
                      value={logoDark}
                      onChange={(value) => handleLogoDark(value)}
                    ></UploadImagePicker>
                  </Form.Item>
                  <Row justify="center">
                    <Text>Logo âm bản</Text>
                  </Row>
                </Col>
              </Row>
              <Form.Item
                name={"phone"}
                label={"Số điện thoại"}
                validateTrigger={"onBlur"}
                rules={[
                  {
                    required: true,
                    pattern: phone_regex,
                    message: "Vui lòng điền số điện thoại",
                  },
                ]}
              >
                <Input disabled={isDisable} placeholder="0975661100"></Input>
              </Form.Item>
              <Form.Item
                name={"address"}
                label={"Website"}
                rules={[
                  {
                    required: true,
                    type: "string",
                    message: "Vui lòng điền địa chỉ website",
                  },
                ]}
              >
                <Input disabled={isDisable} placeholder="https://cubeloyalty.vn"></Input>
              </Form.Item>
              <Form.Item>
                <Button type={save ? "primary" : "default"} onClick={handleClick} style={{ float: "right" }}>
                  {save ? "Lưu" : "Chỉnh sửa"}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Card>
      {/* <div className="upload-space">
        <Card className="upload-card">
          <Row>
            <Col span={24} style={{ fontWeight: 700, display: "flex" }}>
              <div className="abc" style={{ marginRight: 6 }}>
                {nameZaloOA}
              </div>
              <button className="btn-zalo-oa">ZALO OA</button>
            </Col>
            <Col span={24} className="oa-id">
              OA ID : {zaloOAId}
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ height: "100%", marginTop: 20 }}>
              <div className="container-upload">
                <div className="container-upload-header">Hình ảnh logo thương hiệu trên OA (400 x 96 ) , nền trong suốt</div>
                <Form disabled={isDisable} ref={formRef} onFinish={onFinish}>
                  <div className="container-upload-body">
                    <div className="upload-image">
                      <Col xl={24} lg={24}>
                        <Form.Item
                          name={"logolight"}
                          rules={[
                            {
                              required: true,
                              type: "string",
                              message: "Vui lòng upload hình ảnh này",
                            },
                          ]}
                        >
                          <UploadImagePicker
                            crop={{ quality: 1, aspect: 3 }}
                            size={{ width: 400, height: 96 }}
                            hideLibary={true}
                            tags={["logo"]}
                            value={logoLight}
                            onChange={(value) => handleLogoLight(value)}
                          ></UploadImagePicker>
                        </Form.Item>
                      </Col>
                      <div
                        className="content-center"
                        style={{
                          color: "rgba(0, 0, 0, 0.45)",
                          marginTop: -20,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        Logo nguyên bản
                      </div>
                    </div>
                    <div className="upload-image">
                      <Col xl={24} lg={24}>
                        <Form.Item
                          name={"logodard"}
                          rules={[
                            {
                              required: true,
                              type: "string",
                              message: "Vui lòng upload hình ảnh này",
                            },
                          ]}
                        >
                          <UploadImagePicker
                            crop={{ quality: 1, aspect: 3 }}
                            size={{ width: 400, height: 96 }}
                            hideLibary={true}
                            tags={["logo"]}
                            value={logoDark}
                            onChange={(value) => handleLogoDark(value)}
                          ></UploadImagePicker>
                        </Form.Item>
                      </Col>
                      <div className="content-center" style={{ color: "rgba(0, 0, 0, 0.45)", marginTop: -20 }}>
                        Logo Âm bản
                      </div>
                    </div>
                  </div>
                  <Row>
                    <Col span={24} style={{ padding: 0 | 11.5 }}>
                      <div style={{ marginBottom: 13 }}>Số điện thoại</div>
                      <Form.Item
                        name={"phone"}
                        validateTrigger={"onBlur"}
                        rules={[
                          {
                            required: true,
                            pattern: phone_regex,
                            message: "Vui lòng điền số điện thoại",
                          },
                        ]}
                      >
                        <Input placeholder="0975661100"></Input>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: -10 }}>
                    <Col span={24} style={{ padding: 0 | 11.5 }}>
                      <div style={{ marginBottom: 13 }}>Website</div>
                      <Form.Item
                        disabled={true}
                        name={"address"}
                        rules={[
                          {
                            required: true,
                            type: "string",
                            message: "Vui lòng điền địa chị website",
                          },
                        ]}
                      >
                        <Input placeholder="https://google.com"></Input>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
                <Row style={{ marginTop: -25, padding: 0 | 11.5 }}>
                  <Col span={24}>
                    <Button onClick={handleClick} style={{ float: "right" }}>
                      {save ? "Lưu" : "Chỉnh sửa"}
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Card>
      </div> */}
    </>
  );
}

export default ATNDUpload;
